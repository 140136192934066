export const DESKTOP_SCREEN_RESOLUTION = 1280;
// export const TABLET_SCREEN_RESOLUTION = 768;
export const MOBILE_PHONE_HIGH_SCREEN_RESOLUTION = 480;
export const MOBILE_PHONE_SCREEN_RESOLUTION = 320;

export const ZERO_CARDS = 0;

export const NUMBER_OF_CARDS_FOR_DESKTOP = 12;
export const NUMBER_OF_CARDS_FOR_TABLET = 8;
export const NUMBER_OF_CARDS_FOR_MOBILE_PHONE = 5;

export const NUMBER_OF_CARDS_TO_SHOW_MORE_BUTTON = 3;

export const MORE_CARDS_FOR_DESKTOP = 3;
export const MORE_OF_CARDS_FOR_TABLET_AND_PHONE = 2;

export const SHORT_MOVIE_DURATION = 40;

export const HANDLERESIZE_TIMEOUT = 500;
